<template>
  <div>
    <b-modal
      id="modal-modify-special-services-insurance-info"
      :title="$t('reservation[\'Insurance information\']')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      @show="showHandle"
      @hide="hideHandle"
    >
      <template #modal-footer="{close}">
        <div class="w-100 d-flex-center">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            size="md"
            variant="outline-secondary"
            class="center rounded-lg py-75"
            @click="close()"
          >
            {{ $t('reservation.back') }}
          </b-button>
        </div>
      </template>

      <div v-if="dataInsuranceItem">
        <!-- ANCHOR: status PAID -->
        <b-row
          v-if="dataInsuranceItem.policyCode"
          class="mb-50"
        >
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'Contract Code\']') }}
          </b-col>
          <b-col
            cols="7"
            class="fw-700 text-airline"
          >
            {{ dataInsuranceItem.policyCode }}
          </b-col>
        </b-row>

        <b-row class="mb-50">
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'Insurance package\']') }}
          </b-col>
          <b-col
            cols="7"
            class=" fw-700"
          >
            {{ dataInsuranceItem.planName }}
          </b-col>
        </b-row>

        <b-row class="mb-50">
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'Compensation level\']') }}
          </b-col>
          <b-col
            cols="7"
            class="text-danger fw-700"
          >
            {{ formatCurrency(dataInsuranceItem.coverPrice) }} {{ dataInsuranceItem.coverPriceCurrency ? dataInsuranceItem.coverPriceCurrency.toUpperCase() : '' }}
          </b-col>
        </b-row>

        <b-row class="mb-50">
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'paxType\']') }}
          </b-col>
          <b-col
            cols="7"
            class="fw-700"
          >
            {{ dataInsuranceItem.passengerName }}
          </b-col>
        </b-row>

        <b-row class="mb-50">
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'itinerary\']') }}
          </b-col>
          <b-col
            cols="7"
            class="fw-700"
          >
            {{ resolveInsuranceItinerariesName(dataInsuranceItem.flightJourney, 'journeys') }}
          </b-col>
        </b-row>

        <b-row class="mb-50">
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'Start time\']') }}
          </b-col>
          <b-col
            cols="7"
            class="fw-700"
          >
            {{ convertISODateTime(dataInsuranceItem.flightJourney[0].boardingTime, 'UTC').dateTime }}
          </b-col>
        </b-row>

        <!-- ANCHOR: Area -->
        <b-row
          v-if="dataInsuranceItem.geoLimit"
          class="mb-50"
        >
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'Area\']') }}
          </b-col>
          <b-col
            cols="7"
            class="text-info fw-700"
          >
            {{ capitalize(dataInsuranceItem.geoLimit) }}
          </b-col>
        </b-row>

        <b-row class="mb-50">
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'insurancePrice\']') }}
          </b-col>
          <b-col
            cols="7"
            class="text-success fw-700"
          >
            {{ !!dataInsuranceItem.price ? formatCurrency(dataInsuranceItem.price) : '-' }} {{ dataInsuranceItem.currency ? dataInsuranceItem.currency.toUpperCase() : '' }}
          </b-col>
        </b-row>

        <b-row
          v-if="showBenefit"
          class="mb-50"
        >
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'benefit\']') }}
          </b-col>
          <b-col
            cols="7"
            class="text-success fw-700"
          >
            {{ !!dataInsuranceItem.priceCommission ? formatCurrency(dataInsuranceItem.priceCommission) : '-' }} {{ dataInsuranceItem.currency ? dataInsuranceItem.currency.toUpperCase() : '' }}
          </b-col>
        </b-row>

        <b-row class="">
          <b-col
            cols="5 border-bottom"
          >
            {{ $t('reservation[\'status\']') }}
          </b-col>
          <b-col
            cols="7"
          >
            <b-badge :variant="resolveVariantInsuranceServices(dataInsuranceItem.status)">
              {{ resolveInsuranceSpecialServicesName(dataInsuranceItem.status) }}
            </b-badge>
          </b-col>
        </b-row>

        <b-button
          v-if="!isRoleF3"
          v-b-tooltip.hover.window
          variant="flat-warning"
          class="p-50 rounded-circle mx-25 mt-50"
          :title="`${$t(`reservation.${showBenefit ? 'Hide' : 'Show'}`)} ${$t('reservation.priceDetails.titleTable.feeService')}`"
          @click.prevent.stop="showBenefit = !showBenefit"
        >
          <feather-icon
            v-if="showBenefit"
            icon="EyeIcon"
            size="16"
          />
          <feather-icon
            v-else
            icon="EyeOffIcon"
            size="16"
          />
        </b-button>
      </div>

      <div v-else>
        <b-spinner
          variant="primary"
          label="Loading..."
        />
      </div>

      <BRow class="mt-2 justify-content-center">
        <BCol
          v-if="dataInsuranceItem && ['PENDING_PAYMENT'].includes(dataInsuranceItem.status) && !isEmpty(dataInsuranceItem.documents)"
          cols="12"
          sm="6"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-info"
            class="fw-700 px-50 py-75 px-lg-1 mr-75 mr-lg-1 w-100 rounded-lg"
            :disabled="isDisableDownload"
            :href="dataInsuranceItem ? !isEmpty(dataInsuranceItem.documents) ? dataInsuranceItem.documents[0] : '#' : '#'"
          >
            <!-- @click="downloadDocument(dataInsuranceItem)" -->
            <div
              v-if="isDisableDownload"
              class=""
            >
              {{ $t('reservation[\'Please wait\']') }}...
              <BSpinner
                variant="info"
                class="ml-25"
                small
              />
            </div>
            <span v-else>{{ $t('reservation[\'DOWNLOAD THE DRAFT CONTRACT\']') }}</span>
          </b-button>
        </BCol>

        <BCol
          v-if="dataInsuranceItem && ['PAID'].includes(dataInsuranceItem.status)"
          cols="12"
          sm="6"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-info"
            class="fw-700 px-50 py-75 px-lg-50 mr-75 mr-lg-1 w-100 rounded-lg"
            :disabled="isDisableDownload"
            :href="!isEmpty(dataInsuranceItem.documents) ? dataInsuranceItem.documents[0] : '#'"
          >
            <!-- @click="downloadDocument(dataInsuranceItem)" -->
            <div
              v-if="isDisableDownload"
              class=""
            >
              {{ $t('reservation[\'Please wait\']') }}...
              <BSpinner
                variant="info"
                class="ml-25"
                small
              />
            </div>
            <span v-else>{{ $t('reservation[\'DOWNLOAD OFFICIAL CONTRACT\']') }}</span>
          </b-button>
        </BCol>

        <BCol
          v-if="!isRoleF3 && dataInsuranceItem && ['PENDING_PAYMENT'].includes(dataInsuranceItem.status)"
          cols="12"
          sm="6"
          class="text-center mt-75 mt-sm-0"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning"
            class="fw-700 px-50 py-75 px-lg-1 w-100 rounded-lg"
            @click="handleOpenModalEditCoverNote(dataInsuranceItem)"
          >
            {{ $t('reservation[\'EDIT\']') }}
          </b-button>
        </BCol>
      </BRow>
    </b-modal>

    <ModalEditInsuranceCoverNote
      :data-cover-note="dataCoverNote"
    />
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BBadge,
  BSpinner,
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  computed,
  ref,
  toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'

import { resolveInsuranceSpecialServicesName, resolveVariantInsuranceServices } from '@/constants/selectOptions'
import {
  convertISODateTime,
  formatCurrency,
  compareDateBySubtract,
} from '@/@core/utils/filter'
import store from '@/store'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BSpinner,
    BButton,
    BBadge,
    BRow,
    BCol,

    ModalEditInsuranceCoverNote: () => import('./ModalEditInsuranceCoverNote.vue'),
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
    dataInsuranceItem: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup(props) {
    const { toastError } = useToast()
    const { dataBooking, dataInsuranceItem } = toRefs(props)
    const { resolveInsuranceItinerariesName } = useReservationHandle()
    const SUBTRACT_1_HOURS_BY_SECONDS_INS = { subtractValue: 3600, subtractType: 'seconds' }
    const CANNOT_HANDLE_INSURANCE_SERVICE = 'Chỉ có thể mua dịch vụ trước 1 tiếng so với giờ khởi hành đầu tiên'
    const dataCoverNote = ref(null)
    const showBenefit = ref(false)

    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const isDisableDownload = ref(true)
    const MAX_TIME_LOADING = 20
    const defaultEnableMiliSecond = 250
    function timeoutDownload(time) {
      setTimeout(() => {
        isDisableDownload.value = false
      }, time)
    }

    // const isDisableDownload = ref(false)

    // const MAX_RETRIES = 15
    // const DELAY = 3500
    // const timeoutId = ref(null)
    // const stopChecking = ref(false)

    // const resetDataDownload = () => {
    //   timeoutId.value = null
    //   stopChecking.value = false
    //   isDisableDownload.value = false
    // }

    async function handleOpenModalEditCoverNote(item) {
      const departureTime = item.flightJourney[0].boardingTime
      const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)

      if (isBefore || isSame) {
        // toastError({ title: CANNOT_HANDLE_INSURANCE_SERVICE })
        toastError({ title: 'reservation[\'The service can only be purchased 1 hour before the first departure time\']' })
        return
      }

      await new Promise(resolve => {
        dataCoverNote.value = item
        resolve()
      })

      // resetDataDownload()

      clearTimeout(timeoutDownload)
      this.$bvModal.show('modal-reservation-modify-insurance-edit-cover-note')
    }

    // ================= CHECK DOWNLOAD FILE =================
    // const getFileNameFromUrl = url => {
    //   const urlParts = url.split('?')[0].split('/')
    //   return urlParts[urlParts.length - 1]
    // }

    // const downloadDocument = async item => {
    //   if (isEmpty(item.documents)) return
    //   isDisableDownload.value = true
    //   const link = item.documents[0]
    //   const checkFileAvailability = async (retryCount = 0) => {
    //     if (stopChecking.value) return
    //     try {
    //       const headResponse = await fetch(link)
    //       if (headResponse.ok) {
    //         stopChecking.value = true
    //         const data = await fetch(link)
    //         const blob = await data.blob()
    //         const url = window.URL.createObjectURL(blob)
    //         const a = document.createElement('a')
    //         const fileName = getFileNameFromUrl(link) ?? `Insurances_${item?.bookingCode}_${item?.passengerName.split(' ').join('_')}.pdf`
    //         a.href = url
    //         a.download = fileName
    //         document.body.appendChild(a)
    //         a.click()
    //         a.remove()
    //         isDisableDownload.value = false
    //       } else {
    //         throw new Error('File not ready')
    //       }
    //     } catch (error) {
    //       if (retryCount < MAX_RETRIES) {
    //         console.warn(`Retrying insurance document download link... ( ${retryCount + 1}/${MAX_RETRIES} )`)
    //         timeoutId.value = setTimeout(() => checkFileAvailability(retryCount + 1), DELAY)
    //       } else {
    //         resetDataDownload()
    //         console.error('Max retries reached. File is not ready.')
    //       }
    //     }
    //   }
    //   stopChecking.value = false
    //   checkFileAvailability()
    // }

    function showHandle() {
      dataCoverNote.value = null
      isDisableDownload.value = true
      showBenefit.value = false

      if (dataInsuranceItem.value) {
        const updateTime = dataInsuranceItem.value.updatedAt
        const currentTime = new Date().toISOString()

        const getUpdateTime = new Date(updateTime).getTime()
        const getCurrentTime = new Date(currentTime).getTime()
        const betweenTime = Math.round(getCurrentTime / 1000) - Math.round(getUpdateTime / 1000)

        if (betweenTime < MAX_TIME_LOADING) { // (Min:MAX_TIME_LOADING(s)) - currentTime - updateTime
          const timeout = (MAX_TIME_LOADING - betweenTime) * 1000
          timeoutDownload(timeout)
        } else {
          timeoutDownload(defaultEnableMiliSecond)
        }
      }
    }

    function hideHandle() {
      clearTimeout(timeoutDownload)

      // if (timeoutId.value) {
      //   clearTimeout(timeoutId.value)
      // }
      // resetDataDownload()
    }

    return {
      dataBooking,
      showBenefit,
      convertISODateTime,
      showHandle,
      hideHandle,
      isEmpty,
      isRoleF3,
      capitalize,
      resolveInsuranceSpecialServicesName,
      resolveVariantInsuranceServices,
      formatCurrency,
      resolveInsuranceItinerariesName,

      dataCoverNote,
      handleOpenModalEditCoverNote,
      isDisableDownload,
      // downloadDocument,
    }
  },
}
</script>
