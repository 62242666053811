var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-special-services-insurance-info",
      "title": _vm.$t('reservation[\'Insurance information\']'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.showHandle,
      "hide": _vm.hideHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "w-100 d-flex-center"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-lg py-75",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])], 1)];
      }
    }])
  }, [_vm.dataInsuranceItem ? _c('div', [_vm.dataInsuranceItem.policyCode ? _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Contract Code\']')) + " ")]), _c('b-col', {
    staticClass: "fw-700 text-airline",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.dataInsuranceItem.policyCode) + " ")])], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Insurance package\']')) + " ")]), _c('b-col', {
    staticClass: " fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.dataInsuranceItem.planName) + " ")])], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Compensation level\']')) + " ")]), _c('b-col', {
    staticClass: "text-danger fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.dataInsuranceItem.coverPrice)) + " " + _vm._s(_vm.dataInsuranceItem.coverPriceCurrency ? _vm.dataInsuranceItem.coverPriceCurrency.toUpperCase() : '') + " ")])], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'paxType\']')) + " ")]), _c('b-col', {
    staticClass: "fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.dataInsuranceItem.passengerName) + " ")])], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'itinerary\']')) + " ")]), _c('b-col', {
    staticClass: "fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.resolveInsuranceItinerariesName(_vm.dataInsuranceItem.flightJourney, 'journeys')) + " ")])], 1), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Start time\']')) + " ")]), _c('b-col', {
    staticClass: "fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.dataInsuranceItem.flightJourney[0].boardingTime, 'UTC').dateTime) + " ")])], 1), _vm.dataInsuranceItem.geoLimit ? _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Area\']')) + " ")]), _c('b-col', {
    staticClass: "text-info fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.capitalize(_vm.dataInsuranceItem.geoLimit)) + " ")])], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'insurancePrice\']')) + " ")]), _c('b-col', {
    staticClass: "text-success fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(!!_vm.dataInsuranceItem.price ? _vm.formatCurrency(_vm.dataInsuranceItem.price) : '-') + " " + _vm._s(_vm.dataInsuranceItem.currency ? _vm.dataInsuranceItem.currency.toUpperCase() : '') + " ")])], 1), _vm.showBenefit ? _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'benefit\']')) + " ")]), _c('b-col', {
    staticClass: "text-success fw-700",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(!!_vm.dataInsuranceItem.priceCommission ? _vm.formatCurrency(_vm.dataInsuranceItem.priceCommission) : '-') + " " + _vm._s(_vm.dataInsuranceItem.currency ? _vm.dataInsuranceItem.currency.toUpperCase() : '') + " ")])], 1) : _vm._e(), _c('b-row', {}, [_c('b-col', {
    attrs: {
      "cols": "5 border-bottom"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'status\']')) + " ")]), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": _vm.resolveVariantInsuranceServices(_vm.dataInsuranceItem.status)
    }
  }, [_vm._v(" " + _vm._s(_vm.resolveInsuranceSpecialServicesName(_vm.dataInsuranceItem.status)) + " ")])], 1)], 1), !_vm.isRoleF3 ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "p-50 rounded-circle mx-25 mt-50",
    attrs: {
      "variant": "flat-warning",
      "title": "".concat(_vm.$t("reservation.".concat(_vm.showBenefit ? 'Hide' : 'Show')), " ").concat(_vm.$t('reservation.priceDetails.titleTable.feeService'))
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        _vm.showBenefit = !_vm.showBenefit;
      }
    }
  }, [_vm.showBenefit ? _c('feather-icon', {
    attrs: {
      "icon": "EyeIcon",
      "size": "16"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "EyeOffIcon",
      "size": "16"
    }
  })], 1) : _vm._e()], 1) : _c('div', [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Loading..."
    }
  })], 1), _c('BRow', {
    staticClass: "mt-2 justify-content-center"
  }, [_vm.dataInsuranceItem && ['PENDING_PAYMENT'].includes(_vm.dataInsuranceItem.status) && !_vm.isEmpty(_vm.dataInsuranceItem.documents) ? _c('BCol', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "fw-700 px-50 py-75 px-lg-1 mr-75 mr-lg-1 w-100 rounded-lg",
    attrs: {
      "variant": "outline-info",
      "disabled": _vm.isDisableDownload,
      "href": _vm.dataInsuranceItem ? !_vm.isEmpty(_vm.dataInsuranceItem.documents) ? _vm.dataInsuranceItem.documents[0] : '#' : '#'
    }
  }, [_vm.isDisableDownload ? _c('div', {}, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Please wait\']')) + "... "), _c('BSpinner', {
    staticClass: "ml-25",
    attrs: {
      "variant": "info",
      "small": ""
    }
  })], 1) : _c('span', [_vm._v(_vm._s(_vm.$t('reservation[\'DOWNLOAD THE DRAFT CONTRACT\']')))])])], 1) : _vm._e(), _vm.dataInsuranceItem && ['PAID'].includes(_vm.dataInsuranceItem.status) ? _c('BCol', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "fw-700 px-50 py-75 px-lg-50 mr-75 mr-lg-1 w-100 rounded-lg",
    attrs: {
      "variant": "outline-info",
      "disabled": _vm.isDisableDownload,
      "href": !_vm.isEmpty(_vm.dataInsuranceItem.documents) ? _vm.dataInsuranceItem.documents[0] : '#'
    }
  }, [_vm.isDisableDownload ? _c('div', {}, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Please wait\']')) + "... "), _c('BSpinner', {
    staticClass: "ml-25",
    attrs: {
      "variant": "info",
      "small": ""
    }
  })], 1) : _c('span', [_vm._v(_vm._s(_vm.$t('reservation[\'DOWNLOAD OFFICIAL CONTRACT\']')))])])], 1) : _vm._e(), !_vm.isRoleF3 && _vm.dataInsuranceItem && ['PENDING_PAYMENT'].includes(_vm.dataInsuranceItem.status) ? _c('BCol', {
    staticClass: "text-center mt-75 mt-sm-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "fw-700 px-50 py-75 px-lg-1 w-100 rounded-lg",
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": function click($event) {
        return _vm.handleOpenModalEditCoverNote(_vm.dataInsuranceItem);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'EDIT\']')) + " ")])], 1) : _vm._e()], 1)], 1), _c('ModalEditInsuranceCoverNote', {
    attrs: {
      "data-cover-note": _vm.dataCoverNote
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }